module.exports = {
  title: "Furuvik",
  author: "Rebel and Bird",
  description: "Furuvik",
  siteUrl: process.env.GATSBY_SITE_URL ? process.env.GATSBY_SITE_URL : "https://www.furuvik.se",
  locales: [
    { locale: "sv", default: true },
    { locale: "en", default: false },
  ],
  timeZone: "Europe/Stockholm",
  timeUtcOffsetSummerTime: "+02:00",
  timeUtcOffsetNormalTime: "+01:00",
  defaultLocale: "sv",
};
