import EventAttributeStore from "components/stores/event-attribute-store";
class InteractionHelper {
  userInput;

  constructor() {
    if (typeof window !== "undefined") {
      if ("onpointerdown" in window) {
        window.addEventListener("pointerdown", this.pointerDownEvent.bind(this), false);
      } else if ("ontouchstart" in window) {
        window.addEventListener("touchstart", this.setInteractionAsTouch.bind(this), false);
      }
    }
  }

  pointerDownEvent(e) {
    if (e && e.pointerType === "mouse") {
      this.setInteractionAsMouse();
    } else if (e && e.pointerType === "touch") {
      this.setInteractionAsTouch();
    }
  }

  setInteractionAsMouse(force) {
    if (this.userInput === "touch" || force) {
      this.userInput = "mouse";
      document.body.classList.remove("touch");
      document.body.classList.add("mouse");
    }
  }

  setInteractionAsTouch(force) {
    if (this.userInput === "mouse" || force) {
      this.userInput = "touch";
      document.body.classList.remove("mouse");
      document.body.classList.add("touch");
    }
  }

  isInScreen(element, yOffset = 0) {
    if (element) {
      const rect = element.getBoundingClientRect();
      return rect.height > 0 && rect.width > 0 && rect.top + yOffset < EventAttributeStore.getCurrentWindowHeight();
    }
    return false;
  }

  setDefaultState() {
    this.setInteractionAsMouse(true);
  }
}

const singleton = new InteractionHelper();
export default singleton;
